import { PureComponent } from 'react'

import { ClassNames, css } from '@emotion/react'
import styled from '@emotion/styled'
import debounce from 'debounce'
import { withRouter } from 'next/router'
import Autosuggest from 'react-autosuggest'

import Button, { SATIVA } from '@components/button'
import { addressErrorMessages, isMobile } from '@helpers/constants'
import { track } from 'analytics'
import { A11yHiddenLabel } from 'components/form-inputs/label'
import errorHandler from 'error-handler'
import Colors from 'microcomponents/colors'

import defaultTheme from './default-theme.module.scss'
import hideButtonTheme from './hide-button-theme.module.scss'
import { getSuggestionValue, renderSuggestion } from './Suggestion'
import withMaps from './with-maps'

import { bool, func, object, string } from 'prop-types'

const EMPTY_OBJECT = {}
class AutoComplete extends PureComponent {
  static propTypes = {
    theme: object,
    addressLoading: bool,
    buttonCss: func,
    buttonText: string,
    hidebutton: bool,
    maps: object,
    onSubmitSuggestion: func,
    residentialOnlyError: bool,
    formLocation: string
  }

  static defaultProps = {
    theme: null,
    addressLoading: false,
    buttonCss: null,
    buttonText: '',
    hidebutton: false,
    inSampleLocation: false,
    maps: EMPTY_OBJECT,
    onSubmitSuggestion: function () {
      console.warn('AutoComplete onSubmitSuggestion() not implemented')
    },
    residentialOnlyError: false,
    value: '',
    formLocation: ''
  }

  state = {
    highlightedSuggestion: null,
    suggestions: [],
    value: '',
    noAddressError: false
  }

  componentDidMount() {
    debounce(this.retrieveSuggestions(this.state.value), 200)
  }

  componentDidUpdate() {
    if (isMobile) {
      window.scrollTo(0, 0)
    }
  }

  onChange = (event, { newValue, method }) => {
    const newStateValues = { value: newValue }
    const { suggestions, noAddressError } = this.state
    newStateValues.highlightedSuggestion = suggestions.length
      ? suggestions.find((suggestion) => getSuggestionValue(suggestion) === newValue)
      : null
    if (noAddressError) newStateValues.noAddressError = false
    this.setState(newStateValues)
  }

  handleSelect = (event, { suggestion, suggestionValue, method }) => {
    this.setState({
      value: suggestionValue
    })

    this.submitSuggestion(suggestion, suggestionValue)
  }

  handleClick = () => {
    if (this.state.highlightedSuggestion) {
      const suggestion = this.state.highlightedSuggestion
      this.submitSuggestion(suggestion, getSuggestionValue(suggestion))
    } else {
      this.setState({ noAddressError: true })
      this.input.focus()
    }
  }

  // as suggested by https://github.com/moroshko/react-autosuggest/blob/master/FAQ.md#how-do-i-get-the-input-field
  saveInput = (autosuggest) => {
    // For some reasons saveInput is called after unmount and autosuggest doesn't exist at that point
    this.input = autosuggest ? autosuggest.input : null
  }

  submitSuggestion(suggestion, suggestionValue) {
    const { onSubmitSuggestion } = this.props
    // Since all of our data is coming from the autocomplete, we need to hardcode group to suggestions
    track('Places.Select', {
      value: suggestionValue,
      id: suggestion.id,
      group: 'suggestions',
      entryComponent: 'page',
      destination: '/menu'
    })

    onSubmitSuggestion(suggestion)
  }

  retrieveSuggestions = (location) => {
    if (!location || !location.value) return

    track('Places.Input', { value: location.value, entryComponent: 'page', destination: '/menu' })
    const { maps } = this.props

    maps.fetch &&
      maps
        .fetch(location.value)
        .then((suggestions = []) => {
          this.setState({ suggestions })
        })
        .catch((err) => {
          errorHandler(new Error(err.message))
        })
  }

  render() {
    const { suggestions, noAddressError } = this.state
    const { addressLoading, buttonText, buttonCss, hidebutton, residentialOnlyError, formLocation } = this.props

    let { theme } = this.props
    if (!theme) {
      theme = defaultTheme
    }

    const hasError = residentialOnlyError || noAddressError

    const inputClass = [hasError ? inputErr : inputClassCss, hidebutton && hideButtonCss]

    let inputProps = {
      placeholder: 'Enter your delivery address',
      onChange: this.onChange,
      id: `e2e-home-address${formLocation === '' ? formLocation : '-' + formLocation}`,
      type: 'text',
      value: this.state.value,
      'x-autocompletetype': 'address',
      name: 'address'
    }

    if (hasError) {
      inputProps = {
        ...inputProps,
        'aria-invalid': true,
        'aria-describedby': `address-error-message${formLocation === '' ? formLocation : '-' + formLocation}`
      }
    }

    // this is an ugly hack because we have to use legacyCss to emulate CSS modules for this external autosuggest dependency
    if (hidebutton) {
      theme = hideButtonTheme
    }

    return (
      <ClassNames>
        {({ css }) => (
          <AutoSuggestWrapper hasError={hasError}>
            <InputWrapper>
              <A11yHiddenLabel htmlFor={`e2e-home-address-${formLocation}`}>
                Enter your delivery address
              </A11yHiddenLabel>
              <Autosuggest
                suggestions={suggestions}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={this.handleSelect}
                onSuggestionsFetchRequested={debounce(this.retrieveSuggestions, 200)}
                focusFirstSuggestion={!isMobile}
                focusInputOnSuggestionClick={false}
                onSuggestionsClearRequested={() => {}} /* not sure why this is required */
                inputProps={{ ...inputProps, className: css(inputClass) }}
                ref={this.saveInput}
                theme={theme}
              />
              <AutoSuggestError
                id={`address-error-message${formLocation === '' ? formLocation : '-' + formLocation}`}
                hasError={hasError}>
                {hasError &&
                  (noAddressError ? addressErrorMessages.noInput : addressErrorMessages.residentialOnlyError)}
              </AutoSuggestError>
              <A11yHiddenLabel as="div" aria-live="assertive">
                {hasError &&
                  (noAddressError ? addressErrorMessages.noInput : addressErrorMessages.residentialOnlyError)}
              </A11yHiddenLabel>
            </InputWrapper>
            <CallToAction
              buttonType={SATIVA}
              onClick={this.handleClick}
              loading={addressLoading}
              hidebutton={hidebutton}
              buttonCss={buttonCss}
              hasError={hasError}>
              {buttonText || 'Shop Now'}
            </CallToAction>
          </AutoSuggestWrapper>
        )}
      </ClassNames>
    )
  }
}

const CallToAction = styled(Button)`
  width: 10rem;
  display: ${({ hidebutton }) => (hidebutton ? 'none' : 'block')};
  font-weight: 600;
  padding: 1rem;
  font-size: 1.4rem;
  flex: 0 0 auto;

  @media (max-width: 767px) {
    border-radius: 0;
    margin: 1rem 0 1.9rem;
    height: 3.4rem;
    width: auto;
    box-shadow: 0 0.2rem 1.3rem 0 rgba(0, 0, 0, 0.22);
  }
  ${({ buttonCss }) => buttonCss}
`

const AutoSuggestError = styled.div`
  display: ${({ hasError }) => (hasError ? 'block' : 'none')};
  padding: 0.25rem 0.6875rem 0.3125rem 0.25rem;
  background-color: ${Colors.danger[6]};
  text-align: center;
  font-size: 14px;
  color: #ffffff;
`

const AutoSuggestWrapper = styled.div`
  font-size: 1.6rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 767px) {
    font-size: 1.333rem;
    flex-direction: column;
    align-items: center;
  }
`

const InputWrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
  max-width: 26rem;
`

const inputClassCss = css`
  width: 29.25rem;
  padding: 1px 1rem 1px 3rem;
  font-size: 1.6rem;
  border: 2px solid #fff;
  font-weight: 200;
  color: #4a4a4a;
  background-color: #fbfbfb;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 4.5rem;
  line-height: 4rem;
  align-items: flex-start;
  -webkit-appearance: none;

  @media (max-width: 767px) {
    height: 4rem;
    line-height: 4rem;
    padding: 1px 0.833rem 1px 3.167rem;
    background-color: white;
    width: 100%;
    -webkit-appearance: none;
  }

  & ::placeholder {
    font-family: 'Open Sans', Helvetica, Arial, Sans-Serif;
    color: #7a7a7a;
  }

  & :focus {
    outline: none;
  }
`

const inputErr = css`
  ${inputClassCss};

  border: 2px solid ${Colors.danger[6]} !important;
  @media (max-width: 767px) {
    border: 1px solid ${Colors.danger[6]};
  }
`

const hideButtonCss = css`
  border-top-right-radius: 10rem;
  border-bottom-right-radius: 10rem;
  width: 100%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  font-size: 1.4rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`

const hasMaps = withMaps(AutoComplete)
const hasRouter = withRouter(hasMaps)

export default hasRouter
